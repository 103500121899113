import axios from "axios";

const eventApiInfo = {
  baseURL: "https://www.ticketmaster.com/json/search"
};

export const restApiInfo = {
  baseURL: `https://api.ticketjockey.co/api` || "/api"
  // baseURL: `http://localhost:8080/api` || "/api"
};

const createEventApiClient = ({ baseURL }) =>
  axios.create({
    baseURL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    params: {
      countryCode: "US"
    }
  });

const createAwsApiClient = ({ baseURL }) =>
  axios.create({
    baseURL: "",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });

const createRestApiClient = ({ baseURL }) =>
  axios.create({
    baseURL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
    // params: {
    //   useProxy: false
    // }
  });

const createEmailScrapingClient = ({ baseURL }) =>
  axios.create({
    baseURL: "",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });

export const ticketApiClient = createEventApiClient(eventApiInfo);

export const restApiClient = createRestApiClient(restApiInfo);

export const awsApiClient = createAwsApiClient(restApiInfo);

export const emailScrapingClient = createEmailScrapingClient(restApiInfo);
